import React from 'react';
import {
  ButtonGroup,
  Container,
  IconButton,
  Image,
  Stack,
  Text,
  Box,
  HStack,
  Heading,
  Spacer,
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { AiOutlineMail, AiFillPhone } from 'react-icons/ai';
import { SiTiktok } from 'react-icons/si';
import logo from '../../images/logo.png';
function Footer() {
  return (

    <Box
      borderTopRadius={'15px'}
      boxShadow="md"
      mt={20}
      w="100%"
      bg="blackAlpha.100"
      
    >
      <Container maxW="container.lg" as="footer" py={{ base: '12', md: '16' }}>
        <Stack spacing={{ base: '4', md: '5' }}>
          <Stack justify="space-between" direction="row" align="center">
            <HStack>
              <Image maxH={'50px'} borderRadius={'15px'} src={logo}></Image>
              <Heading size="sm">
                Tot ce trebuie să știi pentru 10 la bac la informatică
              </Heading>
            </HStack>
            <Spacer />
            <ButtonGroup variant="ghost">
              <IconButton
                as="a"
                href="#"
                aria-label="LinkedIn"
                icon={<SiTiktok fontSize="1.25rem" />}
              />
              <IconButton
                as="a"
                href="#"
                aria-label="GitHub"
                icon={<FaInstagram fontSize="1.25rem" />}
              />
              <IconButton
                as="a"
                href="#"
                aria-label="Twitter"
                icon={<FaFacebook fontSize="1.25rem" />}
              />
              <IconButton
                as="a"
                href="#"
                aria-label="Twitter"
                icon={<FaWhatsapp fontSize="1.25rem" />}
              />
              <IconButton
                as="a"
                href="#"
                aria-label="Twitter"
                icon={<AiOutlineMail fontSize="1.25rem" />}
              />
              <IconButton
                as="a"
                href="#"
                aria-label="Twitter"
                icon={<AiFillPhone fontSize="1.25rem" />}
              />
            </ButtonGroup>
          </Stack>
          <Text fontSize="sm" color="subtle">
            &copy; {new Date().getFullYear()} Alexandra Laicu. Toate drepturile
            rezervate.{' '}
          </Text>
        </Stack>
      </Container>
    </Box>
  );
}

export default Footer;
