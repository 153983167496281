import React from 'react';
import BackToDashboard from '../../components/BackToDashboard/BackToDashboard';
import { Center, VStack, Heading, Container } from '@chakra-ui/react';
import {
  lessonsDictionary,
  thumbnails,
  hasHomework,
  exercisesDictionary,
} from '../../constants';
import HorizontalImageCard from '../../components/HorizontalImageCard/HorizontalImageCard';
import { useSelector } from 'react-redux';
import useGetContentAvailability from '../../hooks/getContentAvailability'; // Adjust the import path as necessary

function HomeworkIndex() {
  const exercisesNumber = [
    'Pseudocod - 100',
    'C/C++ - 100',
    'Tablouri - 39, Siruri - 59, Structuri - 45',
    'Backtracking - 95, Recursivitate - 96',
    'Grafuri - 114, Arbori - 63',
    'S3.1 - 86, S3.2 - 114, S3.3 - 97',
  ];


  const signedUp = useSelector(state => state.checkCurrentUser.signedUp);
  const driveLink = useSelector(
    state => state.checkCurrentUser.driveLink
  );

  const dripFrequency = useSelector(state => state.checkCurrentUser.dripFrequency);
  const availableContent = useGetContentAvailability(signedUp, dripFrequency)


  return (
    <>
      <Center >
        <VStack  width={{ base: '100%', sm: '100%' }}>
          <Container maxW="container.xl">
            <BackToDashboard />
          </Container>
          <Heading size="xl" mt={10}>
            Capitole Teme
          </Heading>
          <Heading size="md">Temele apar odata la {dripFrequency} zile.</Heading>
          <Container maxW="container.xl" >
            {Object.entries(lessonsDictionary).map(([k, v], i) => {
              if (i <= availableContent) {
                return (
                hasHomework[i] && (
                  <HorizontalImageCard
                    thumbnail={thumbnails[thumbnails.length - 1 - i]}
                    title={`Temă ${exercisesDictionary[hasHomework[i]].join(
                      ', '
                    )}`}
                    footer={`Număr Exerciții: ${
                      exercisesNumber[hasHomework[i] - 1]
                    }`}
                    link={`/exercitii/${hasHomework[i]}`}
                    buttonText="Mergi la Exerciții"
                    link2={driveLink}
                    buttonText2='Încarcă tema'
                  />
                )
              );
              }
            })}
          </Container>
        </VStack>
      </Center>
    </>
  );
}

export default HomeworkIndex;
