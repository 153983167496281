import React from 'react';
import BackToDashboard from '../../components/BackToDashboard/BackToDashboard';
import {
  Center,
  VStack,
  Heading,
  Card,
  Image,
  Stack,
  Text,
  CardBody,
  Button,
  CardFooter,
  Container,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { lessonsDictionary, thumbnails } from '../../constants';
import useGetContentAvailability from '../../hooks/getContentAvailability'; // Adjust the import path as necessary
import HorizontalImageCard from '../../components/HorizontalImageCard/HorizontalImageCard';


function TheoryIndex() {

  const signedUp = useSelector(state => state.checkCurrentUser.signedUp);
  const dripFrequency = useSelector(state => state.checkCurrentUser.dripFrequency);
  const availableContent = useGetContentAvailability(signedUp, dripFrequency)
  console.log(availableContent);

  return (
    <>
      <Center>
        <VStack>
          <Container maxW="container.xl">
            {' '}
            <BackToDashboard />
          </Container>
          <Heading size="xl" mt={10}>
            Module Teorie 
          </Heading>
          <Heading size="md">Lecțiile apar odata la {dripFrequency} zile.</Heading>
          <Container maxW="container.xl">
            {Object.entries(lessonsDictionary).map(([k, v], i) => {
              if (i <= availableContent) {
              return (
                <HorizontalImageCard
                  thumbnail={thumbnails[thumbnails.length - 1 - i]}
                  title={v.title}
                  description={v.description}
                  footer={`Temele se încarcă pe drive.`}
                  link={`/module-${i + 1}/1`}
                  buttonText="Vezi Video-uri"
                />
              );
              }
            })}
          </Container>
        </VStack>
      </Center>
    </>
  );
}

export default TheoryIndex;
