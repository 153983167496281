import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import VideoDetail from './pages/VideoDetail/VideoDetail';
import { Navbar } from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import SignUp from './pages/SignUp/SignUp';
import Lesson from './pages/Lesson/Lesson';
import ProtectedRoute from './pages/ProtectedRoute/ProtectedRoute';
import LiveSession from './pages/LiveSession/LiveSession';
import SimpleOneVideoPage from './pages/SimpleOneVideoPage/SimpleOneVideoPage';
import SimpleVideo from './pages/SimpleVideo/SimpleVideo';
import CourseIndex from './pages/CourseIndex/CourseIndex';
import HomeworkIndex from './pages/HomeworkIndex/HomeworkIndex';
import TheoryIndex from './pages/TheoryIndex/TheoryIndex';
import LiveLessonIndex from './pages/LiveLessonIndex/LiveLessonIndex';
import { Spacer, Flex} from '@chakra-ui/react';

function App() {
  return (
    <BrowserRouter>
      <Flex direction="column" minHeight="100vh">
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/sign-in" element={<SignUp />} />
        <Route
          path="/:module/:number"
          element={
            <ProtectedRoute>
              <VideoDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/teorie"
          element={
            <ProtectedRoute>
              <TheoryIndex />
            </ProtectedRoute>
          }
        />

        <Route
          path="/cuprins-curs"
          element={
            <ProtectedRoute>
              <CourseIndex />
            </ProtectedRoute>
          }
        />
        <Route
          path="/exercitii"
          element={
            <ProtectedRoute>
              <HomeworkIndex />
            </ProtectedRoute>
          }
        />
        <Route
          path="/exercitii/:chapter"
          element={
            <ProtectedRoute>
              <Lesson />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sesiuni-live"
          element={
            <ProtectedRoute>
              <LiveLessonIndex />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sesiuni-live/:chapter"
          element={
            <ProtectedRoute>
              <LiveSession />
            </ProtectedRoute>
          }
        />

        <Route
          path="/rezolvari-bac/:number"
          element={
            <ProtectedRoute>
              <SimpleVideo />
            </ProtectedRoute>
          }
        />

        <Route
          path="/grafuri-materiale-aditionale/:number"
          element={
            <ProtectedRoute>
              <SimpleVideo />
            </ProtectedRoute>
          }
        />

        <Route path="/castigator-giveaway" element={<SimpleOneVideoPage />} />
      </Routes>
      <Spacer />
      <Footer />
      </Flex>
    </BrowserRouter>
    
    
  );
}

export default App;
