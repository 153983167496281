import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  Spacer,
} from '@chakra-ui/react';

import { PasswordField } from '../../components/PasswordField/PasswordField';
import { Link, useNavigate } from 'react-router-dom';
import { auth, logInWithEmailAndPassword } from '../../services/firebase';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUser } from '../../features/checkCurrentUserSlice';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const isLoggedIn = useSelector(state => state.checkCurrentUser.isLoggedIn);
  const firstName = useSelector(state => state.checkCurrentUser.firstName);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    'in use effect';
    if (isLoggedIn === true && firstName !== '') {
      navigate('/');
    }
  }, [firstName, isLoggedIn]);

  const handleAction = async () => {
    console.log(email, password);
    if (email !== '' && password !== '') {
      try {
        const { user } = await logInWithEmailAndPassword(email, password);
        dispatch(
          setCurrentUser({ userId: user.uid, isAdmin: false, isLoggedIn: true })
        );

        navigate('/');
      } catch (error) {
        if (error.code === 'auth/wrong-password') {
          toast.error('Parola este gresita');
        }
        if (error.code === 'auth/user-not-found') {
          toast.error('Adresa de email nu este corecta');
        }
      }
    }
  };
  return (
    <Container
      maxW="lg"
      py={{
        base: '12',
        md: '24',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack
            spacing={{
              base: '2',
              md: '3',
            }}
            textAlign="center"
          >
            <Heading
              size={{
                base: 'md',
                md: 'lg',
              }}
            >
              Tot ce trebuie să știi pentru 10 la bac la informatică
            </Heading>

            <Text color="muted">
              Autentifică-te pentru a continua. Dacă nu ai cont, achiziționează
              programul
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.alexandralaicu.ro/formular-inscriere"
              >
                <Button variant="link" colorScheme={'purple'}>
                  aici.
                </Button>
              </a>
            </Text>
          </Stack>
        </Stack>
        <Box
          py={{
            base: '0',
            sm: '8',
          }}
          px={{
            base: '4',
            sm: '10',
          }}
          bg={{
            base: 'transparent',
            sm: 'bg-surface',
          }}
          boxShadow={{
            base: 'none',
            sm: 'md',
          }}
          borderRadius={{
            base: 'none',
            sm: 'xl',
          }}
        >
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  type="email"
                  onChange={e => setEmail(e.target.value)}
                />
              </FormControl>
              <PasswordField setPassword={setPassword} value={password} />
            </Stack>
            <HStack justify="space-between">
              {/* <Checkbox defaultChecked>Remember me</Checkbox> */}
              <Button variant="link" colorScheme="purple" size="sm">
                Ai uitat parola?
              </Button>
            </HStack>
            <Stack spacing="6">
              <Button
                onClick={handleAction}
                variant="solid"
                colorScheme={'purple'}
              >
                Autentifică-te
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

export default SignUp;
