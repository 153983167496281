import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Image,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Stack,
  Button,
  StackDivider,
  SimpleGrid,
  Highlight,
  Center,
  Progress,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
} from '@chakra-ui/react';
import { lessonsDictionary, nameArray } from '../../constants.js';
import { Navbar } from '../../components/Navbar/Navbar';
import { Logo } from '../../Logo';
import courseCover from '../../images/course-cover.png';
import { useEffect } from 'react';
import UsersDataService from '../../services/userDatabase';
import useGetUserPersonalData from '../../hooks/getUserPersonalData';
import { useSelector } from 'react-redux';
import { useNavigate, useNavigation } from 'react-router-dom';

function Dashboard(props) {
  const uid = useSelector(state => state.checkCurrentUser.userId);

  const lastModule = useSelector(
    state => state.checkCurrentUser.lessonsStats.lastModule
  );
  const lastLesson = useSelector(
    state => state.checkCurrentUser.lessonsStats.lastLesson
  );

  const fullVideoStats = useSelector(
    state => state.checkCurrentUser.fullVideoStats
  );

  const driveLink = useSelector(
    state => state.checkCurrentUser.driveLink
  );


  useGetUserPersonalData(uid);
  const navigate = useNavigate();

  return (
    <Box textAlign="center" fontSize="xl">
      <VStack
        textAlign={'center'}
        m="auto"
        width={{ base: '95%', md: '90%', lg: '75%' }}
        spacing={8}
      >
        {/* <Image
          borderRadius={'15px'}
          boxShadow={'md'}
          mt={10}
          width={'50%'}
          src={courseCover}
        ></Image> */}

        <Box />
        <Card w={'100%'} overflow="hidden" boxShadow={'md'}>
          <Heading mt={13} mb={27} size="lg">
            Cum să parcurgi acest program?
          </Heading>
          <Image
            m="auto"
            objectFit="cover"
            objectPosition={'center'}
            maxW={{ base: '100%', sm: '700px' }}
            src={courseCover}
            alt="Coperta curs"
            borderRadius={'15px'}
          />

          <Stack>
            <CardBody m="auto" maxW={'85%'}>
              <Divider mb={5} />
              <Heading size="md">1. Vizionează lecțiile de teorie</Heading>

              <Text py="2">
                Continua să vizionezi lecțiile înregistrate, pentru a înțelege
                în profunzime conceptele de teorie. Acestea te vor ajuta să ai o
                viziune de ansamblu asupra informaticii și îți vor clădi o bază
                solidă, pentru a putea rezolva corect toate problemele din
                subiectele de bac.
              </Text>

              <Text mt={3} fontSize={'md'}>
                Nu sări peste nicio lecție, iar dacă nu înțelegi ceva, reia
                video-ul ori de câte ori este necesar.
              </Text>
              <CardFooter textAlign={'center'}>
                <Button
                  onClick={() => {
                    console.log(`${lastModule}/${lastLesson}`);
                    navigate(`${lastModule}/${lastLesson}`);
                  }}
                  colorScheme="purple"
                  margin={'auto'}
                >
                  Mergi la lecțiile de teorie
                </Button>
              </CardFooter>
              <Divider mt={3} mb={5} />
              <Heading size="md">
                2. Rezolvă tema pentru fiecare capitol
              </Heading>
              <Text py="2">
                După ce vizualizezi lecțiile de teorie încearcă să rezolvi cât
                mai multe exerciții de la tema aferentă capitolului studiat.
                Doar așa îți poți pune în practică cunoștințele teoretice
                dobândite prin vizionarea de video-uri. Apoi încarcă tema pe
                drive.
              </Text>
              <Text mt={3} fontSize={'md'}>
                Abordează fiecare problemă, chiar dacă la început nu pare
                ușoară. Pune întrebări ca să te deblochezi și să
                progresezi accelerat.
              </Text>
              <CardFooter textAlign={'center'}>
                <Button
                  onClick={() => {
                    console.log(`${lastModule}/${lastLesson}`);
                    navigate(`/exercitii/4`);
                  }}
                  colorScheme="purple"
                  margin={'auto'}
                >
                  Mergi la exerciții
                </Button>
              </CardFooter>
              {/* <Divider mt={3} mb={5} />
              <Heading size="md">
                3. Intră la întâlnirile săptămânale de Q&A
              </Heading>
              <Text py="2">
                Întâlnirile LIVE cu proful de info sunt esențiale pentru a
                fundamenta tot ceea ce ai parcurs pe cont propriu în săptămâna
                anterioară. Vei vedea care este modalitatea corectă de a aborda
                diferite tipuri de exerciții și vei primi explicații
                personalizate pentru problemele tale.
              </Text>
              <Text mt={3} fontSize={'md'}>
                Notează-ți pe o foaie ceea ce vrei să întrebi și implică-te cât
                mai mult. Astfel vei putea vedea exact care e nivelul tău și pe
                ce trebuie să mai insiști.
              </Text> */}
              {/* <CardFooter textAlign={'center'}>
                <a
                  style={{ margin: 'auto' }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://zoom.us/j/97380608145"
                >
                  <Button colorScheme="purple" m={'auto'}>
                    Intră pe zoom
                  </Button>
                </a>
              </CardFooter> */}
            </CardBody>
          </Stack>
        </Card>

        <SimpleGrid w={'100%'} spacing={4} columns={{ base: 1, md: 2 }}>
          {/* <Card w={'100%'}>
            <CardHeader>
              <Heading size="md"> Sesiuni Q&A </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                <Highlight
                  query="marți de la ora 19:00."
                  styles={{
                    px: '2',
                    py: '1',
                    bg: 'purple.100',
                    rounded: 'full',
                  }}
                >
                  Întâlnirile LIVE au loc în fiecare sâmbătă de la ora 14:30.
                </Highlight>
              </Text>
              <Text mt={3} fontSize={'md'}>
                Asigură-te că înainte de întâlniri ai parcurs toate video-urile
                de teorie și ai rezolvat exercițiile din capitolul săptămânii
                respective.
              </Text>
            </CardBody>
            <CardFooter textAlign={'center'}>
              <a
                style={{ margin: 'auto' }}
                target="_blank"
                rel="noreferrer"
                href="https://us05web.zoom.us/j/97380608145?pwd=jzrTX3upPzZIMvb92tC8XGhlaviowO.1"
              >
                <Button variant="outline" colorScheme="purple" m={'auto'}>
                  Intră pe zoom
                </Button>
              </a>
            </CardFooter>
          </Card> */}
          <Card w={'100%'}>
            <CardHeader>
              <Heading size="md"> Drive teme </Heading>
            </CardHeader>
            <CardBody>
              <Text mt={3} fontSize={'md'}>
                Asigură-te că folosești șablonul prestabilit și că încarci tema
                în folderul corect, cu numele capitolului. Directorul de drive pe care îl vei folosi este cel care se deschide dupa ce dai click pe butonul de mai jos.
              </Text>
            </CardBody>
            <CardFooter textAlign={'center'}>
              <a
                style={{ margin: 'auto' }}
                target="_blank"
                rel="noreferrer"
                href={driveLink}
              >
                <Button variant="outline" colorScheme="purple" m={'auto'}>
                  Încarcă o temă
                </Button>
              </a>
            </CardFooter>
          </Card>
          <Card w={'100%'}>
            <CardHeader>
              <Heading size="md"> Grup suport </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                <Highlight
                  query="marți de la ora 19:00."
                  styles={{
                    px: '2',
                    py: '1',
                    bg: 'purple.100',
                    rounded: 'full',
                  }}
                >
                  Poți pune întrebări 24/7.
                </Highlight>
              </Text>
              <Text mt={3} fontSize={'md'}>
                Dacă ai întrebări legate de teme, video-uri, generalități sau
                orice altceva, nu ezita să scrii.
                <br />
                <Highlight
                  query="Suntem aici să ne ajutăm unii pe ceilalți."
                  styles={{
                    px: '2',
                    py: '1',
                    bg: 'purple.100',
                    rounded: 'full',
                  }}
                >
                  Suntem aici să ne ajutăm unii pe ceilalți.
                </Highlight>
              </Text>
            </CardBody>
            <CardFooter textAlign={'center'}>
              <a
                style={{ margin: 'auto' }}
                target="_blank"
                rel="noreferrer"
                href="https://chat.whatsapp.com/C2Qx8YShlZ46siNt8uG5IB"
              >
                <Button variant="outline" colorScheme="purple" m={'auto'}>
                  Mesaj WhatsApp
                </Button>
              </a>
            </CardFooter>
          </Card>
        </SimpleGrid>
        <SimpleGrid w={'100%'} spacing={4} columns={{ base: 1, md: 1 }}>
       
          <Card w={'100%'}>
            <CardHeader>
              <Heading size="md"> Plățile mele </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                Asigură-te că efectuezi fiecare plată până la data limită. În
                caz contrar, accesul tău în platformă va fi blocat automat.
              </Text>
              <Box>
                <Text mt={3} fontSize={'md'}>
                  <Highlight
                    query="ALEVLA TECH HOUSE SRL"
                    styles={{
                      px: '2',
                      py: '1',
                      bg: 'purple.100',
                      rounded: 'full',
                    }}
                  >
                    Nume beneficiar ALEVLA SOFTWARE SOLUTIONS SRL
                  </Highlight>
                </Text>
                <Text mt={3} fontSize={'md'}>
                  <Highlight
                    query="RO73BTRLRONCRT0650617501"
                    styles={{
                      px: '2',
                      py: '1',
                      bg: 'purple.100',
                      rounded: 'full',
                    }}
                  >
                    IBAN RO73BTRLRONCRT0650617501
                  </Highlight>
                </Text>
                <Text mt={3} fontSize={'md'}>
                  <Highlight
                    query="RON"
                    styles={{
                      px: '2',
                      py: '1',
                      bg: 'purple.100',
                      rounded: 'full',
                    }}
                  >
                    Valuta RON
                  </Highlight>
                </Text>
                <Text mt={3} fontSize={'md'}>
                  <Highlight
                    query="BTRLRO22"
                    styles={{
                      px: '2',
                      py: '1',
                      bg: 'purple.100',
                      rounded: 'full',
                    }}
                  >
                    SWIFT BTRLRO22
                  </Highlight>
                </Text>
              </Box>
            </CardBody>
            {/* <CardFooter textAlign={'center'}>
              <a
                style={{ margin: 'auto' }}
                target="_blank"
                rel="noreferrer"
                href="https://www.alexandralaicu.ro/oferta"
              >
                <Button variant="outline" colorScheme="purple" m={'auto'}>
                  Plătește rata
                </Button>
              </a>
            </CardFooter> */}
          </Card>
        </SimpleGrid>
        <Card w={'100%'} variant="outline" boxShadow={'md'}>
          <CardHeader>
            <Heading size="md">Statistici</Heading>
          </CardHeader>

          <CardBody>
            <Stack divider={<StackDivider />} spacing="4">
              <Box>
                <Heading size="sm" textTransform="uppercase">
                  Ultima lecție vizualizată
                </Heading>
                <Text pt="2" fontSize="sm">
                  <b>
                    Modulul {lastModule.split('-')[1]} Lecția {lastLesson}
                  </b>
                  <b>-</b>{' '}
                  {lastModule &&
                    lessonsDictionary[lastModule.split('-')[1]].title}{' '}
                </Text>
              </Box>
              <Box>
                <Heading size="sm" textTransform="uppercase">
                  Numarul de lecții finalizate
                </Heading>
                <Text pt="2" fontSize="sm">
                  Ai finalizat {Object.entries(fullVideoStats).length} lecții
                  din {nameArray.length}.
                </Text>
                <Progress
                  mt={5}
                  colorScheme={'purple'}
                  value={
                    (Object.entries(fullVideoStats).length / nameArray.length) *
                    100
                  }
                />
              </Box>
            </Stack>
          </CardBody>
        </Card>
      </VStack>
    </Box>
  );
}

export default Dashboard;
