import { useState, useEffect } from "react";

const useGetContentAvailability = (signupDate, interval = 14) => {
  console.log(signupDate);
  const [availableContent, setAvailableContent] = useState(0);

  // Function to calculate how many days since the user signed up
  const calculateDaysSinceSignup = (signupDate) => {
    const now = new Date();
    const signup = new Date(signupDate);
    const diffTime = Math.abs(now - signup);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  useEffect(() => {
    if (signupDate) {
      const daysSinceSignup = calculateDaysSinceSignup(signupDate);
      // Calculate how much content the user has unlocked based on interval
      const unlocked = Math.floor(daysSinceSignup / interval);
      setAvailableContent(unlocked);
    }
  }, [signupDate, interval]);

  return availableContent;
};

export default useGetContentAvailability;
