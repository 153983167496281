import React from 'react';
import {
  Heading,
  Card,
  Image,
  Stack,
  Text,
  CardBody,
  Button,
  CardFooter,
  Box,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

function HorizontalImageCard(props) {
  return (
    <Card
   
      direction={{ base: 'column', sm: 'row' }}
      overflow="hidden"
      mt={10}
      variant="outline"
    >
      <Image
        objectFit="cover"
        maxW={{ base: '100%', sm: '200px' }}
        src={props.thumbnail}
        alt="Caffe Latte"
      />

      <Stack>
        <CardBody>
          <Heading size="md">{props.title}</Heading>

          <Text py="2">{props.description}</Text>
          <Text as="b" py="2">
            {props.footer}
          </Text>
        </CardBody>

        <CardFooter>
          <Link to={props.link}>
            <Button variant="solid" colorScheme="purple">
              {props.buttonText}
            </Button>
          </Link>

          {props.link2 && (
            <>
            <Box p={2}></Box>
          <Link to={props.link2}>
            <Button variant="outline" colorScheme="purple">
              {props.buttonText2}
            </Button>
          </Link>
          </>
          )
          }
        </CardFooter>
      </Stack>
    </Card>
  );
}

export default HorizontalImageCard;
