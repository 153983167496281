import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../../services/firebase';
import useCheckIsLoggedIn from '../../hooks/checkIsLoggedIn';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import {
  setCurrentUser,
  setCurrentUserPersonalData,
} from '../../features/checkCurrentUserSlice';
import UsersDataService from '../../services/userDatabase';

function ProtectedRoute({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    onAuthStateChanged(auth, async user => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        dispatch(
          setCurrentUser({ userId: uid, isAdmin: false, isLoggedIn: true })
        );
        try {
          const user = await UsersDataService.getCurrentUserPersonalData(uid);
          const userData = user.data();
          console.log(userData);
          dispatch(
            setCurrentUserPersonalData({
              firstName: userData.firstName,
              lastName: userData.lastName,
              email: userData.email,
              signedUp: userData.signedUp.toDate(),
              dripFrequency: userData.dripFrequency,
              driveLink: userData.driveLink,
              lessonsStats:
                userData.lessonsStats !== undefined
                  ? userData.lessonsStats
                  : { lastLesson: 1, lastModule: 'module-1' },
              fullVideoStats:
                userData.fullVideoStats !== undefined
                  ? userData.fullVideoStats
                  : {},
            })
          );
        } catch (e) {
          console.log(e);
        }
      } else {
        // User is signed out
        // ...
        dispatch(
          setCurrentUser({ userId: '', isAdmin: false, isLoggedIn: false })
        );
      }
    });
  }, []);

  const isLoggedIn = useSelector(state => state.checkCurrentUser.isLoggedIn);
  const userId = useSelector(state => state.checkCurrentUser.userId);
  console.log(isLoggedIn);

  if (isLoggedIn) {
    return React.cloneElement(children, {
      userId: userId,
      isLoggedIn: isLoggedIn,
    });
  }

  return <Navigate to="/sign-in" />;
}

export default ProtectedRoute;
